<template>
  <div class="DAV-workshop__trunk">
    <component-title color="green">
      <h3 slot="title">
        페이지 레이아웃 설정
        <tooltip
          color="#fff"
          top="30px"
          :text="`상단과 하단 레이아웃 디자인을 메인 페이지만 다르게 구성할지 또는 전체 페이지를 공통으로 구성할지 설정할 수 있습니다.<br/>
          ･ 메인 페이지 전용 옵션을 사용하는 경우 메인 페이지에서만 적용되는 상단, 하단 디자인을 편집할 수 있으며 메인 외 페이지에서 편집한 상단, 하단 디자인은 모든 페이지에 공통 적용됩니다.<br/>
          ･ 전체 페이지 공통 옵션을 사용하면 메인 포함 모든 페이지에 상단, 하단 디자인이 공통으로 적용됩니다.`"
        />
      </h3>
    </component-title>
    <div class="DAV-blockClass">
      <section>
        <h4 class="editLayout__title">상단(Header)</h4>
        <div
          class="editLayout__label editLayout__header"
          :class="{'editLayout__header--common': headerChecked === 'common'}"
        >
          <label
            class="frmBlock__label"
            v-for="value in ['main', 'common']"
            :key="value"
          >
            <input
              type="radio"
              name="headerLayout"
              :value="value"
              @input="changeLayout"
              :checked="headerChecked === value"
            />
            <span v-if="value === 'main'">메인 페이지 전용 상단</span>
            <span v-else>전체 페이지 공통 상단</span>
          </label>
        </div>
      </section>
      <section>
        <h4 class="editLayout__title">하단(Footer)</h4>
        <div
          class="editLayout__label editLayout__footer"
          :class="{'editLayout__footer--common': footerChecked === 'common'}"
        >
          <label
            class="frmBlock__label"
            v-for="value in ['main', 'common']"
            :key="value"
          >
            <input
              type="radio"
              name="footerLayout"
              :value="value"
              @input="changeLayout"
              :checked="footerChecked === value"
            />
            <span v-if="value === 'main'">메인 페이지 전용 하단</span>
            <span v-else>전체 페이지 공통 하단</span>
          </label>
        </div>
      </section>
    </div>
    <button
      class="DAV-blockClass__submit"
      :class="setBtnClass"
      @click="setLayouts"
    >
      설정 저장하기 <i class="xi-angle-right-min"></i>
    </button>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleEditorContainer = createNamespacedHelpers("ModuleEditorContainer");

export default {
  data() {
    return {
      layouts: null,
      groups: null,
      headerChecked: null,
      footerChecked: null
    };
  },
  computed: {
    // class binding - 설정 저장하기
    setBtnClass() {
      return {
        "DAV-blockClass__submit--active": true
      };
    },

    ...ModuleTheme.mapGetters(["getThemeLoadData"]),
    ...ModuleEditorContainer.mapGetters(["getEditablePages"])
  },
  methods: {
    // 페이지 레이아웃 선택
    changeLayout(e) {
      if (e.target.name === "headerLayout") {
        this.headerChecked = e.target.value;
      }
      if (e.target.name === "footerLayout") {
        this.footerChecked = e.target.value;
      }
    },
    // 페이지 레이아웃 적용
    setLayouts() {
      this.layouts.header =
        this.headerChecked === "main"
          ? this.groups.main.header
          : this.groups.common.header;
      this.layouts.footer =
        this.footerChecked === "main"
          ? this.groups.main.footer
          : this.groups.common.footer;

      this.setThemeLoad(this.getThemeLoadData);
      this.setAttach(true);
      this.setEvent("layout");
      this.setPanel("ComponentList");
    },

    ...ModuleTheme.mapMutations(["setThemeLoad"]),
    ...ModuleEditor.mapMutations(["setAttach", "setPanel"]),
    ...ModuleEditorContainer.mapMutations(["setEvent"])
  },
  mounted() {
    this.layouts = this.getEditablePages[0].layouts; // 메인
    this.groups = this.getThemeLoadData.layoutGroups;

    this.headerChecked =
      this.layouts.header === this.groups.common.header ? "common" : "main";
    this.footerChecked =
      this.layouts.footer === this.groups.common.footer ? "common" : "main";
  },
  components: {
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.editLayout {
  &__title {
    color: #606060;
    font-weight: 500;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 8px;
  }
  &__label {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 16px;
    padding-left: 100px;
    background-repeat: no-repeat;
    background-position: 0 0;
  }
  &__header {
    background-image: url($path + "bul_layout_header.svg");
  }
  &__header--common {
    background-image: url($path + "bul_layout_header_common.svg");
  }
  &__footer {
    background-image: url($path + "bul_layout_footer.svg");
  }
  &__footer--common {
    background-image: url($path + "bul_layout_footer_common.svg");
  }
}
.DAV-blockClass {
  top: 56px !important;
  bottom: 56px;

  section {
    padding: 12px 16px 24px;
    background-color: #ffffff;
    margin-bottom: 8px;
  }
}
.DAV-blockClass__submit {
  z-index: 2;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: #dcdcdc;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;

  &--active {
    background-color: #54c7a2;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-blockClass__submit {
    width: 421px;
  }
  .DAV-workshop__heading {
    h3 {
      padding-left: 32px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-workshop__trunk {
    top: 40px;
  }
}
</style>
