var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunk"
  }, [_c('component-title', {
    attrs: {
      "color": "green"
    }
  }, [_c('h3', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" 페이지 레이아웃 설정 "), _c('tooltip', {
    attrs: {
      "color": "#fff",
      "top": "30px",
      "text": "\uC0C1\uB2E8\uACFC \uD558\uB2E8 \uB808\uC774\uC544\uC6C3 \uB514\uC790\uC778\uC744 \uBA54\uC778 \uD398\uC774\uC9C0\uB9CC \uB2E4\uB974\uAC8C \uAD6C\uC131\uD560\uC9C0 \uB610\uB294 \uC804\uCCB4 \uD398\uC774\uC9C0\uB97C \uACF5\uD1B5\uC73C\uB85C \uAD6C\uC131\uD560\uC9C0 \uC124\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n        \uFF65 \uBA54\uC778 \uD398\uC774\uC9C0 \uC804\uC6A9 \uC635\uC158\uC744 \uC0AC\uC6A9\uD558\uB294 \uACBD\uC6B0 \uBA54\uC778 \uD398\uC774\uC9C0\uC5D0\uC11C\uB9CC \uC801\uC6A9\uB418\uB294 \uC0C1\uB2E8, \uD558\uB2E8 \uB514\uC790\uC778\uC744 \uD3B8\uC9D1\uD560 \uC218 \uC788\uC73C\uBA70 \uBA54\uC778 \uC678 \uD398\uC774\uC9C0\uC5D0\uC11C \uD3B8\uC9D1\uD55C \uC0C1\uB2E8, \uD558\uB2E8 \uB514\uC790\uC778\uC740 \uBAA8\uB4E0 \uD398\uC774\uC9C0\uC5D0 \uACF5\uD1B5 \uC801\uC6A9\uB429\uB2C8\uB2E4.<br/>\n        \uFF65 \uC804\uCCB4 \uD398\uC774\uC9C0 \uACF5\uD1B5 \uC635\uC158\uC744 \uC0AC\uC6A9\uD558\uBA74 \uBA54\uC778 \uD3EC\uD568 \uBAA8\uB4E0 \uD398\uC774\uC9C0\uC5D0 \uC0C1\uB2E8, \uD558\uB2E8 \uB514\uC790\uC778\uC774 \uACF5\uD1B5\uC73C\uB85C \uC801\uC6A9\uB429\uB2C8\uB2E4."
    }
  })], 1)]), _c('div', {
    staticClass: "DAV-blockClass"
  }, [_c('section', [_c('h4', {
    staticClass: "editLayout__title"
  }, [_vm._v("상단(Header)")]), _c('div', {
    staticClass: "editLayout__label editLayout__header",
    class: {
      'editLayout__header--common': _vm.headerChecked === 'common'
    }
  }, _vm._l(['main', 'common'], function (value) {
    return _c('label', {
      key: value,
      staticClass: "frmBlock__label"
    }, [_c('input', {
      attrs: {
        "type": "radio",
        "name": "headerLayout"
      },
      domProps: {
        "value": value,
        "checked": _vm.headerChecked === value
      },
      on: {
        "input": _vm.changeLayout
      }
    }), value === 'main' ? _c('span', [_vm._v("메인 페이지 전용 상단")]) : _c('span', [_vm._v("전체 페이지 공통 상단")])]);
  }), 0)]), _c('section', [_c('h4', {
    staticClass: "editLayout__title"
  }, [_vm._v("하단(Footer)")]), _c('div', {
    staticClass: "editLayout__label editLayout__footer",
    class: {
      'editLayout__footer--common': _vm.footerChecked === 'common'
    }
  }, _vm._l(['main', 'common'], function (value) {
    return _c('label', {
      key: value,
      staticClass: "frmBlock__label"
    }, [_c('input', {
      attrs: {
        "type": "radio",
        "name": "footerLayout"
      },
      domProps: {
        "value": value,
        "checked": _vm.footerChecked === value
      },
      on: {
        "input": _vm.changeLayout
      }
    }), value === 'main' ? _c('span', [_vm._v("메인 페이지 전용 하단")]) : _c('span', [_vm._v("전체 페이지 공통 하단")])]);
  }), 0)])]), _c('button', {
    staticClass: "DAV-blockClass__submit",
    class: _vm.setBtnClass,
    on: {
      "click": _vm.setLayouts
    }
  }, [_vm._v(" 설정 저장하기 "), _c('i', {
    staticClass: "xi-angle-right-min"
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }